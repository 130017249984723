import { createStore } from 'vuex'

export default createStore({
  state: {
    membership : '',
    title : '',
    first_name : '',
    middle_name : '',
    surname : '',
    email : '',
    phone : '',
    branch : '',
    country : '',
    postal_address : '',
    postal_code : '',
    kmpdc : '',
    specialization : '',
    payment_method : '',
    amount : 0,
    currency : 'Ksh',
    url : 'https://conference.techbridge.co.ke/'
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
